.menu-mobile {
  position: absolute;
  top: 56px;
  z-index: 1000;
  width: 100%;
}

@media screen and (max-device-width: 480px) {
  .destacados {
    margin-top: -10px !important;
  }
  .follow-us {
    margin-top: 20px !important;
  }

  .location-list-nav {
    height: 76px !important;
  }

  .location-list-nav--title {
    font-size: 20px !important;
  }

  .locations-list--zone-title {
    font-size: 16px !important;
  }

  .location-list-nav--chevron {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    height: 100%;
    background-color: #f4f4f4;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: background-color .4s ease;
    display: block !important;
  }
  .sucursal-zone {
    font-size: 24px !important;
  }
  .sucursal-title {
    font-size: 15px !important;
  }
  .sucursal-address {
    font-size: 15px !important;
  }
  .sucursal-open-at {
    font-size: 15px !important;
    margin-top: 5px !important;
  }
  .sucursal-open-at {
    font-size: 15px !important;
    margin-top: 5px !important;
  }
  .sucursal-whatsapp {
    font-size: 15px !important;
    margin-top: 5px !important;
  }


  .box-middle_text2 {
    margin-top: -4px !important;
  }

  .franquicias .text-section {
    margin-top: -10px !important;
  }
  .productos .familia-la-pinocha {
    margin-top: 0px !important;
  }

  .fondo-medio-back {
    height: 173px;
  }
  .g-recaptcha {
    transform: scale(0.7);
    transform-origin: 0 0;
    margin-top: 7%;
  }
  .text-section {
    padding: 46px;
  }
  .style-text-section {
    font-family: "montserrat-medium";
    font-size: 15px;
  }
  .conocenos .text-section .bold {
    font-size: 19px !important;
    font-family: "montserrat-bold" !important;
  }

  .conocenos .text-section div {
    font-size: 17px !important;
    font-weight: 700;
  }
  .text-section {
    margin-top: 0 !important;
  }
  .bold-text_section {
    margin-bottom: 1px;
  }
  .menu-mobile div.par {
    background-color: #f59f88;
  }
  .header .nav-menu {
    display: none;
  }
  .menu-mobile {
    text-align: center;
  }

  .menu-mobile div {
    height: 60px !important;
    background-color: #f18f78;
    padding-top: 20px;
    color: white;
    font-family: "montserrat-bold";
  }

  .burger-menu {
    margin-right: 10px !important;
  }

  .header {
    min-height: 57px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .header .logo {
    margin-left: 30px;
  }

  .destacado {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .nav-menu {
    visibility: hidden;
  }
  .logo {
    justify-content: left;
  }
  .upper-section {
    align-items: center;
  }
  .right-footer-content {
    box-sizing: border-box;
    width: -1%;
    padding-left: 22%;
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 19px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Montserrat";
    justify-content: center;
    flex-direction: column;
    align-content: center;
  }
  .link-header {
    color: white;
    margin: 5px;
    text-decoration: none;
    font-family: "montserrat-regular-semibold";
  }
}

@media screen and (min-device-width: 480px) {
  .footer-submit {
    min-width: 26%;
  }
}
@media screen and (min-device-width: 480px) and (max-device-width: 767px) {
  .menu-mobile {
    text-align: center;
  }

  .conocenos .text-section .bold {
    font-size: 19px !important;
    font-family: "montserrat-bold" !important;
  }

  .conocenos .text-section div {
    font-size: 17px !important;
  }

  .menu-mobile a {
    color: white !important;
  }

  .menu-mobile div {
    height: 60px !important;
    background-color: #f18f78;
    padding-top: 20px;
    color: white;
    font-family: "montserrat-bold";
  }

  .menu-mobile div.par {
    background-color: #f59f88;
  }

  .burger-menu {
    margin-right: 10px !important;
  }

  .header {
    justify-content: space-between !important;
  }

  .header .nav-menu {
    display: none;
  }

  .header {
    min-height: 57px !important;
    display: flex !important;
    align-items: center !important;
  }
  .header .logo {
    margin-left: 30px;
  }
  .footer-right-sm {
    margin-left: 0% !important;
  }
  .destacado {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .nav-menu {
    visibility: hidden;
  }
  .logo {
    justify-content: left;
  }
  .upper-section {
    align-items: center;
  }
  .right-footer-content {
    box-sizing: border-box;
    width: -1%;
    padding-left: 22%;
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 19px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .footer .rights {
    margin-top: 45px;
  }
  .left-logos {
    width: 100%;
    padding-left: 6%;
    padding-right: 0;
    position: absolute;
    margin-top: -25%;
  }
  .right-footer-content_group-1 {
    margin-top: 20px;
    display: inline-flex;
  }
  .right-footer-content_group-2 {
    margin-top: 20px;
  }
  .contac-em {
    margin-top: 20px;
  }

  .footer-img {
    margin-left: -65px;
  }
  .right-footer-content_title {
    margin-right: 10px;
  }
}
@media screen and (min-device-width: 768px) {
  .menu-mobile {
    display: none !important;
  }
  .right-footer-text {
    margin-left: 50%;
  }
  .burger-menu {
    display: none !important;
  }
  .right-footer-content {
    display: inline-flex;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 19px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .logo-footer {
    position: relative !important;
  }
  .right-footer-content_group-1 {
    width: 50px;
  }
  .upper-sections {
    justify-content: space-evenly;
    align-content: center;
  }
}
@media only screen and (max-width: 481px) {
  .sucursal-list {
    padding: 0 10px;
  }

  .sucursal {
    width: 100% !important;
    text-align: left;
    padding: 20px !important;
  }

  .familia-la-pinocha {
    font-size: 30px !important;
    margin-bottom: 10px;
  }

  .conoce-nuestra-historia-1,
  .conoce-nuestra-historia-2 {
    font-size: 14px !important;
    font-weight: bold;
    margin-left: 15px;
    margin-right: 15px;
  }

  .productos .top-section {
    height: 145px;
  }
}

@media screen and (max-device-width: 768px) {
  .burger-menu.float-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .blank-separator {
    min-height: 57px;
    min-width: 1px;
    background-color: white;
    margin-right: -5px;
  }
}
@media only screen and (max-width: 700px) {
  /*img {
    max-height: 200px;
  }*/
  .product-list {
    overflow: scroll;
    overflow-x: scroll;
    justify-content: unset !important;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .product-list div {
    padding-right: 30px;
  }

  .product-list::-webkit-scrollbar, .productos .banners::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .productos .banners {
    /*min-height: 200px;*/
    width: 100%;
    overflow-x: scroll;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: unset !important;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  img#confituras-img, img#submarinos-img, img#tabletas-img, img#alfajores-img {
    margin-left: 5%;
  }
}

@media only screen and (max-width: 520px) {
  .contact-us-form {
    margin-right: 30px;
    margin-left: 30px;
  }

  .choco-name {
    font-size: 18px !important;
  }

  .choco-description {
    /*margin-top: 4%;*/
    margin-bottom: 6%;
    font-size: 16px !important;
  }
}

.container,
.container-sm {
  max-width: 100% !important;
}

@media only screen and (max-width: 980px) {
  .fix-padding-top-footer-top {
    padding-top: 20px !important;
  }
}

.menu-mobile a {
  color: white !important;
}

.footer .rights {
  margin-top: 45px;
}
.left-logos {
  width: 86px;
  height: 100px;
  position: relative;
  padding-right: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-content: center;
}
.right-footer-content_group-1 {
  margin-top: 20px;
}
.right-footer-content_group-2 {
  margin-top: 20px;
}
.contac-em {
  margin-top: 20px;
}

.footer-img {
  margin-left: -65px;
}
.right-footer-content_title {
  margin-right: 122px;
}
.right-footer-content_group-1 {
  margin-top: 20px;
  display: inline-flex;
}
