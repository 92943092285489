@font-face {
  font-family: "montserrat-regular";
  src: url("fonts/monserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "montserrat-medium";
  src: url("fonts/monserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "montserrat-bold";
  src: url("fonts/monserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "montserrat-semi-bold";
  src: url("fonts/monserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "montserrat-regular-semibold";
  src: url("fonts/monserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "montserrat-light";
  src: url("fonts/monserrat/Montserrat-Light.ttf");
}

:root {
  --switch-move-position: 3px;
  --switch-size: 26px;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "montserrat-regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.pre-header {
  background-color: #f18f78;
}

.header {
  max-width: 1140px;
  margin: auto;
  flex: 1;
  display: flex;
  height: 10%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  position: relative;
}
.nav-menu {
  margin-top: 1%;
}
.list-elements {
  font-size: 11px;
  display: inline-flex;
}

.logo {
  flex-direction: row;
}
.social-icon {
  margin-top: 5px;
  flex-direction: row;
}
.icon-1 {
  margin-left: 20px;
}
.icon-2 {
  margin-left: 10px;
}
ul {
  list-style: none;
}
.link-header {
  color: white;
  margin: 5px;
  text-decoration: none;
  font-family: "montserrat-regular-semibold";
}

a:hover {
  color: white;
  text-decoration: none;
}
.box-middle {
  margin-top: 1%;
  margin-bottom: 2%;
  width: 100%;
}
.box-middle_img1 {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.box-middle_img2 {
  position: absolute;
  width: 100%;
  z-index: -1;
}
.box-middle_text {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-middle_text1 {
  color: white;
  font-size: 22px;
  font-family: "montserrat-bold";
}
.box-middle_text2 {
  margin-top: -18px;
  color: white;
  font-size: 16px;
  font-family: "montserrat-medium";
}

.botton-img1 {
  position: absolute;
  background-image: url("img/estamos-cerca-home.png");
  color: #f18f78;
}

.destacado {
  width: 414px;
  height: 500px;
  background-color: #ddd;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.destacados {
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.destacado-1 {
  background-image: url("img/estamos-cerca-home.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.destacado-2 {
  background-image: url("img/sabores-supremos-home.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.destacado-3 {
  background-image: url("img/somos-la-pinocha-home.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.fondo-medio-back {
  background-image: url("img/fondo-medio-back.png");
}

.footer {
  margin-top: 2%;
  background-color: #525251;
  color: white;
  display: flex;
  flex-direction: column;
}

.upper-section {
  display: flex;
  justify-content: space-evenly;
}

.left-footer {
  display: flex;
  flex-direction: row;
}

.left-footer .text-left-footer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 50%;
}
.title {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Montserrat";
}

.footer .rights {
  background-color: #252525;
  height: 49px;
  padding: 30px;

  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-content: center;
}

.call-to-action {
  border: 2px solid #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.destacado-middle-section {
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-content: center;
}
.message {
  color: white;
  font-size: 23px;
  font-family: "montserrat-bold";
  margin-bottom: 6%;
}
.call-to-action {
  font-size: 10px;
  margin: auto;
  width: 177px;
  height: 40px;
}

.destacado-middle-section .call-to-action span {
  font-size: 13px;
  font-family: "montserrat-semi-bold";
}
.call-to-action span {
  padding-top: 13px;
  padding-bottom: 13px;
  color: white;
}
.logo-footer {
  width: 50px;
  margin-top: 15px;
  position: absolute;
}
.text-left-footer {
  align-self: center;
  margin-left: 5px;
}
.bajada-de-linea {
  width: 250px;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Montserrat";
}
.right-footer {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  align-self: center;
}
.right-footer-text {
  flex-wrap: wrap;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Montserrat";
}
.rights {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
}
.follow-us {
  align-self: center;

  width: 75px;
}
.follow-text {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Montserrat";
  margin-bottom: 5px;
}
.footer-logo-right {
  margin-top: 5px;
}

.footer-logo-right a:first-child {
  margin-left: 0;
}
.footer-logo-right a {
  margin: 3px;
}

.top-section {
  background-color: #fdad99;
  height: 318px;
  color: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.franquicias .top-section {
  height: 170px;
}

.top-section .familia-la-pinocha {
  font-size: 49px;
  font-family: "montserrat-light";
  line-height: normal;
  margin-bottom: 10px;
  margin-top: -60px;
  font-weight: 300;
}

.top-section ,
.top-section .conoce-nuestra-historia,
.top-section .conoce-nuestra-historia-2 {
  font-family: "montserrat-bold";
  font-size: 20px;
  font-weight: 700;
}
.conoce-nuestra-historia-1 {
  font-family: "montserrat-bold";
  font-size: 20px;
}
.conocenos .conoce-nuestra-historia-2 {
  font-size: 20px;
  margin-bottom: 45px;
}

.conocenos .logo-conocenos {
  margin-top: -120px;
  height: 214px;
  text-align: center;
}

.bold {
  font-weight: bold;
  font-family: "montserrat-bold";
}

.conocenos .text-section,
.franquicias .text-section {
  text-align: center;
  max-width: 729px;
  margin: auto;
  margin-top: 80px;
}
.style-text-section {
  font-size: 18px;
  font-weight: 400;
}
.conocenos .text-section .bold {
  font-size: 25px;
}

.conocenos .text-section div {
  font-size: 20px;
}

.contact-us-form .form-item {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-us-form .form-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-center {
  justify-content: center;
}
.contact-us-input {
  height: 50px;
  width: 100%;
}
.contact-us-area {
  width: 100%;
  height: 196px;
}
.form-footer {
  margin-top: 35px;
  margin-bottom: 15%;
}

.footer-submit {
  height: 51px;
  background-color: #ef8f7a;
  border: none;
  color: white;
  font-size: 11px;
  font-weight: bold;
  font-family: "montserrat";
  line-height: 30px;
  padding: 10px;
}
.form-label {
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 42px;
  color: #2b2b2b;
  font-family: "montserrat-regular";
  text-align: center;
  margin-top: 45px;
  margin-bottom: 35px;
  font-weight: 400;
}

.form-item.first {
  margin-top: 0px !important;
}

.productos-rose {
  background-color: #fce1da;
}

.product-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 82%;
  text-align: center;
  margin: auto;
  padding: 30px;
}

.productos .banners {
  text-align: center;
  display: flex;
  justify-content: center;
  background-image: url("img/fondo-rosa.png");
  background-repeat: repeat-x;
  background-size: auto 50%;
  background-color: white;
}

.productos .description-chocolate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-home {
  flex-direction: column;
  text-align: center;
  display: flex;
  color: white;
}

.middle-banner-home {
  margin: auto;
}

.contact-em {
  text-decoration: none;
  color: white;
}

.carrousel-arrow {
  width: 30px !important;
  height: 30px !important;
}
.sucursal-zone-box {
  display: flex;
}

.sucursal-zone {
  font-size: 50px;
  letter-spacing: -1px;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  font-weight: 300;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.sucursal {
  padding: 20px 30px;
  border-bottom: 1px solid #e1e1dd;
  position: relative;
  color: inherit;
  cursor: pointer;
}

.sucursal.active,
.sucursal:hover {
  color: inherit;
  background-color: #f4f4f4;
}

.sucursal-title {
  font-size: 22px;
  line-height: 1.5;
  letter-spacing: -1px;
  font-weight: bold;
}
.sucursal-address {
  font-size: 22px;
  letter-spacing: 0;
  font-weight: lighter;
}
.sucursal-whatsapp {
  font-size: 24px;
  letter-spacing: 0px;
  color: #212529;
}
.sucursal-whatsapp:hover {
  color: #212529;
}
.card {
  width: 18rem;
  border: none;
}
.card-title {
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 50px;
  font-weight: 700;
  font-family: "Montserrat";
}
.container-fluid.full-width {
  padding: 0 !important;
}

.react-slideshow-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.description-chocolate {
  margin-top: 60px;
}

.productos-rose .container {
  padding-top: 30px;
  padding-bottom: 30px;
}

.product-list a {
  color: #a05845 !important;
  font-size: 13px;
  font-family: "montserrat-medium";
  text-transform: uppercase;
}

.product-list a:hover {
  /* color: white !important; */
  font-weight: bold;
}

.choco-name {
  font-family: "montserrat-bold";
  font-size: 30px;
  text-transform: capitalize;
  margin-top: 1%;
}

.choco-description {
  font-family: "montserrat-light";
  font-size: 24px;
  margin-bottom: 3%;
}

.margin-queres-conocer {
  margin-bottom: 5%;
}

.row-destacados {
  justify-content: center;
}

.contact-us-center {
  max-width: 520px;
  margin: auto;
}

.white-background {
  margin-top: -30px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-us-form .form-footer button {
  margin-left: -67px;
}

.carousel-root {
  display: flex;
  justify-content: center;
}

.semibold-14 {
  font-family: "montserrat-semi-bold";
  font-size: 14px;
}

.regular-14 {
  font-family: "montserrat-regular";
  font-size: 14px;
}

.footer-right-sm {
  margin-left: 25%;
}

.footer-sm {
  height: 145px;
}

.fix-padding-escribinos {
  padding-top: 0%;
}

.fix-padding-top-footer-top {
  padding-top: 37px;
}

.row.rights span {
  font-size: 12px;
  font-family: "montserrat-regular";
}

.sucursal-open-at {
  max-width: 319px;
}

.sucursal-open-at span,
.sucursal-whatsapp span {
  margin-left: 5px;
}

.sucursal-open-at img,
.sucursal-whatsapp img {
  width: 25px;
}

.sucursales .top-section .familia-la-pinocha,
.franquicias .top-section .familia-la-pinocha {
  margin-top: 0px !important;
}

.middle-banner-home .upper {
  font-family: "montserrat-bold";
  font-size: 13px;
}

.middle-banner-home .middle {
  font-family: "montserrat-light";
  /*font-size: 50px;*/
  font-size: 40px;
  max-width: 410px;
  margin: 20px 0;
}

.middle-banner-home .call-to-action {
  font-family: "montserrat-semi-bold";
  font-size: 13px;
}

button:disabled {
  opacity: 0.5;
}

#root {
  overflow-x: hidden !important;
}

.footer-submit {
  text-transform: uppercase;
}

.header-bottom-margin {
  width: 100%;
  background-color: white;
  min-height: 1px;
}

.loading-overlay {
  background-color: rgba(255,255,255,0.75);
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  padding: 10%;
}

.country-selector {
  flex: 1;
  text-align: right;
  padding-right: 15px;
  position: relative;
  z-index: 100;
  cursor: pointer;
}

.country-selector label {
  margin-bottom: 0;
}

.country-selector .flag {
  max-width: 28px;
  margin: 6px;
}

.country-selector .chevron {
  transition: all ease 0.3s;
}

#country-selector-check {
  display: none;
}

.country-selector-list {
  display: none;
  background: #525251;
  color: #fff;
  position: absolute;
  right: 14px;
  white-space: nowrap;
}

.country-selector-list a {
  color: #fff;
  display: inline-block;
  padding-right: 10px;
}

.country-selector-list-option {
  padding: 3px;
  text-align: center;
}

.country-selector-list-option .flag {
  margin-top: 4px;
}

#country-selector-check:checked ~ .country-selector {
  /*background: #525251;*/
}

#country-selector-check:checked ~ .country-selector .country-selector-list {
  display: block;
}

#country-selector-check:checked ~ .country-selector .chevron {
  transform: rotate(180deg);
}

@media screen and (min-width: 480px) {
  .country-selector {
    position: absolute;
    right: 0;
  }

  .country-selector-list {
    right: 0;
  }
}

.sucursales {
  font-family: Montserrat, sans-serif;
  /*background-color: #f0dfdb;*/
  background-image: linear-gradient(#fdad99 200px, #f0dfdb 200px);
  padding-bottom: 40px;
  margin-bottom: -2%;
}

.sucursales .top-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  background-color: #fdad99;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 60px;
}

.locations-floating-box {
  width: 85%;
  /*min-height: 144px;*/
  background: #fff;
  margin: auto;
  border-radius: 12px;
  padding: 20px 30px;
  text-align: left;
  position: absolute;
  z-index: 1;
  box-shadow: 1px 3px 6px #666;
  top: 5vh;
  /*top: 60px;*/
  /*opacity: 0.6;
  transition: opacity 0.4s ease;*/
}

.locations-floating-box:hover,
.locations-floating-box:focus,
.locations-floating-box:target,
.locations-floating-box:active {
  /*opacity: 1;*/
}

.locations-floating-box-title {
  color: #282828;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
}


.switch[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

.switch-label {
  display: inline-block;
  cursor: pointer;
  text-indent: -9999px;
  width: calc(var(--switch-size) + var(--switch-size));
  height: calc(var(--switch-size) + var(--switch-move-position) + var(--switch-move-position));
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.5s ease;
  margin: 0;
}

.switch-label:after {
  content: '';
  position: absolute;
  top: var(--switch-move-position);
  left: var(--switch-move-position);
  width: var(--switch-size);
  height: var(--switch-size);
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switch:checked + .switch-label {
  background: #e17b65;
}

.switch:checked + .switch-label:after {
  left: calc(100% - (var(--switch-move-position)));
  transform: translateX(-100%);
}

.switch-label:active:after {
  width: var(--switch-size);
}

.places-search-container {
  width: 100%;
  position: relative;
}

.places-search-container::after {
  background-image: url("img/icon-search.svg");
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  z-index: 1000;
  position: absolute;
  top: 12px;
  right: 32px;
  background-color: #f3f3f3;
}

#places-search {
  background-color: #f3f3f3;
  border-radius: 30px;
  padding: 10px 24px;
  font-size: 12px;
  font-weight: 600;
  color: #797979;
  border: #e1e1e1 solid 1px;
  width: 100%;
}

.locations-list {
  background-color: #fff;
  width: calc(100% - 10px);
  /*margin: -60px auto 0;*/
  margin: auto;
  border-radius: 12px;
  /*height: calc(60vh + 42px);*/
  width: 100%;
}

.locations-list--zone {

}
.location-list-nav {
  width: 100%;
  margin: auto;
  padding: 25px 30px;
  height: 106px;
  position: relative;
  border-bottom: 1px solid #e1e1dd;
}

.location-list-nav .locations-list--zone-title {
  padding: 0;
  font-size: 19px;
}


.locations-list-nav--checkbox {
  display: none;
  position: absolute;
  opacity: 0;
  height: 0;
}

.location-list-nav--title {
  text-transform: uppercase;
  color: #b76d5d;
  font-size: 18px;
  font-weight: bold;
  transition: all .4s ease;
}

.location-list-nav--chevron {
  display: none;
}

.location-list-nav--chevron-icon {
  background-image: url("img/chevron down_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  width: 60%;
  height: 100%;
  display: block;
  transition: all .4s ease;
}

.locations-list-nav-dropdown-list {
  height: 0;
  clear:both;
  width:100%;
  overflow: hidden;
  transition: all .4s ease;
  opacity: 0;
}

.locations-list-nav--checkbox:checked ~ .locations-list-nav-dropdown-list {
  height: calc(60vh - 108px);
  /*height: 50vh;*/
  overflow: auto;
  opacity: 1;
}
.locations-list-nav--checkbox:checked ~ .location-list-nav .location-list-nav--chevron {
  background-color: transparent;
}
.locations-list-nav--checkbox:checked ~ .location-list-nav .location-list-nav--chevron-icon {
  transform: rotate(-180deg);
}
.locations-list-nav--checkbox:checked ~ .location-list-nav .locations-list--zone-title {
  opacity: 1;
}
.locations-list-nav--checkbox:checked ~ .location-list-nav .location-list-nav--title {
  font-size: 14px !important;
}

.locations-list--zone-title {
  color: #282828;
  font-weight: bold;
  font-size: 25px;
  text-transform: uppercase;
  padding: 20px 30px;
  transition: all .4s ease;
}

.locations-list--zone-title.active {
  width: 80%;
  z-index: 1000;
  position: absolute;
  top: -20px;
  background-color: #fff;
  padding: 10px 30px;
}

.sucursal-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.sucursal-icon.whatsapp {
  background-image: url("img/icon-wapp.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.sucursal-icon.telephone {
  background-image: url("img/icon-phone.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.map {
  width: 100%;
  /*height: calc(42px + 60vh);*/
  height: 60vh;
  border-radius: 12px;
  /*margin-top: -60px;*/
}

.map-wrapper {
  display: flex;
  justify-content: center;
  height: 60vh;
  background-color: rgba(200, 200, 200, 0.5);
  border-radius: 12px;
  /*height: calc(42px + 60vh);*/
  /*margin-top: -60px;*/
  align-items: center;
}

.gm-style .gm-style-iw-c {
  padding: 0;
  max-height: initial !important;
}

.gm-style-iw-d {
  margin: 5px !important;
  overflow: hidden !important;
  max-height: initial !important;
}

.gm-ui-hover-effect>span {
  background-color: #fff;
  width: 24px !important;
  height: 24px !important;
  margin: 0 !important;
  position: relative;
  top: 10px;
  right: 5px;
}

.infowindow {
  display: inline-block;
  max-width: 300px;
  min-width: 200px;
  text-align: left;
}

.infowindow-header {
  font-family: montserrat-medium, sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  background-color: #ef8f7a;
  padding: 20px 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #fff;
}

.infowindow-header-icon {
  width: 42px;
  height: 42px;
  margin-right: 4px;
}

.infowindow-content {
  padding: 20px 20px 10px;
}

.infowindow-title {
  font-family: montserrat-semi-bold, sans-serif;
  color: #333333;
  font-size: 16px;
  margin-bottom: 5px;
}

.infowindow-address {
  font-family: montserrat-regular, sans-serif;
  color: #333333;
  font-size: 16px;
}

.infowindow-phone {
  font-family: montserrat-regular, sans-serif;
  color: #333333;
  font-size: 16px;
  margin: 6px 0;
}

.infowindow-phone a,
.infowindow-phone a:active,
.infowindow-phone a:hover {
  color: #333;
  line-height: 1.1;
}

.infowindow-phone img {
  margin-right: 2px;
  height: 22px;
  position: relative;
  bottom: 2px;
}
/*
iframe {
  display: none;
}*/
